export default {
  name: "ClaimsDetailComponent",
  data() {
    return {
      texts: "",
      dateTableDetail: [],
      isMobile: false,
      isTablet: false,
      isFixed : false,
      isCenter : false,
      imgWidthResponive: false,
      rating : 4.5,
      loaded: false,
      imgSelected:'',
      aImages:[
        {
          img:'https://picsum.photos/200'
        },
        {
          img:'https://picsum.photos/200'
        },
        {
          img:'https://picsum.photos/200'
        },
        {
          img:'https://picsum.photos/200'
        },
        {
          img:'https://picsum.photos/200'
        },
      ],
      
    };
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  created(){
    const img = new Image()
    img.src = this.imgSrc
    img.addEventListener('load', ()=>{
      this.loaded = true
    })
  },
  mounted () {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeMount() {
    this.texts = FILE.claimsTexts[this.selectLanguage];
    this.claimsDetail();
    this.imgSelected = this.aImages[0].img
  },
  methods: {
    onResize () {
      this.isFixed = window.innerWidth > 959
      this.isMobile = window.innerWidth < 600 
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960  
      this.isTablet =   window.innerWidth > 959 && window.innerWidth < 1264  	

    },
    claimsDetail() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            // iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.dateTableDetail = response.data.results;

          // if(this.articleDetailArr.aImages.length >= 1){
          //   this.imgSelected = this.articleDetailArr.aImages[0].oImages.lg
          // }
          // if (this.selectLanguage == "en") {
          //   this.articleName = response.data.results.aTranslations.find(
          //     (res) => {
          //       return res.sLang == "en";
          //     }
          //   ).sName;
          //   this.articleDescription = response.data.results.aTranslations.find(
          //     (res) => {
          //       return res.sLang == "en";
          //     }
          //   ).sDescription;
          // } else if (this.selectLanguage == "sp") {
          //   this.articleName = response.data.results.aTranslations.find(
          //     (res) => {
          //       return res.sLang == "sp";
          //     }
          //   ).sName;
          //   this.articleDescription = response.data.results.aTranslations.find(
          //     (res) => {
          //       return res.sLang == "sp";
          //     }
          //   ).sDescription;
          // } else {
          //   this.articleName = "undefined";
          //   this.articleDescription = "undefined";
          // }

        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    selectedimg(img){
      this.imgSelected =  img.img
    }

  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
        this.claimsDetail();

      }
    },
  },
};